export const SM_SCREEN_BREAKPOINT = 640;

export const MD_SCREEN_BREAKPOINT = 768;

export const LG_SCREEN_BREAKPOINT = 1024;

export const XL_SCREEN_BREAKPOINT = 1280;

export const XXL_SCREEN_BREAKPOINT = 1536;

export const PROD_BASE_URL = 'https://kimlocfloral.com';

export const STORAGE_AUTH_ACCESS_TOKEN = 'AuthenticationAccessToken';

export const userGender: { label: string; value: number }[] = [
  {
    label: 'labels.female',
    value: 0
  },
  {
    label: 'labels.male',
    value: 1
  }
];
