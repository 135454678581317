import { useEffect, useState } from 'react';

interface IWindowSize {
  width: number;
  height: number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({ width: 0, height: 0 });

  useEffect(() => {
    const handleGenerateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleGenerateWindowSize);

    handleGenerateWindowSize();

    return () => window.removeEventListener('resize', handleGenerateWindowSize);
  }, []);

  return windowSize;
};
