import i18n from 'i18next';
import { getCurrentLng } from 'utils/getCurrentLng';

import { TLocaleNamespace } from '@app/common/types';
import { createSlice } from '@reduxjs/toolkit';

interface ILanguageState {
  currentLng: TLocaleNamespace;
}

const initialState: ILanguageState = {
  currentLng: getCurrentLng() as TLocaleNamespace
};

const languageSlice = createSlice({
  name: 'language/changeLanguage',
  initialState: initialState,
  reducers: {
    changeLanguage: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      i18n.changeLanguage(action.payload).then(() => {});

      state.currentLng = action.payload;
    }
  }
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
