import MainLayout from 'components/mainLayout';
import ToastProvider from 'components/toast';
import AuthProvider from 'context/AuthContext';
import { FC } from 'react';
import fetcher from 'services/utils/fetcher';
import { SWRConfig } from 'swr';

const App: FC = () => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        fetcher
      }}
    >
      <AuthProvider>
        <ToastProvider />
        <MainLayout />
      </AuthProvider>
    </SWRConfig>
  );
};

export default App;
