import LoadingMask from 'components/loadingMask';
import { RouteBasePath } from 'components/mainLayout/routeBasePath';
import { generateToastErrorMsg } from 'components/toast/utils';
import { STORAGE_AUTH_ACCESS_TOKEN } from 'helpers/constant';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { createContext, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLogin, useUserInfo } from 'services/auth';
import { IUseAuthData } from 'services/auth/useUserInfo';

export interface ILoginValues {
  emailOrUsername: string;
  password: string;
}

interface IAuthContext {
  userData: IUseAuthData | null;
  // eslint-disable-next-line no-unused-vars
  onLogin: (values: ILoginValues) => void;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onLogout: (isReloadPage?: boolean) => void;
}

interface IProps {
  children: JSX.Element | JSX.Element[];
}

/* eslint-disable @typescript-eslint/no-empty-function */
const initialAuthContextValues: IAuthContext = {
  userData: null,
  onLogin: () => {},
  isLoading: false,
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  onLogout: (isReloadPage = true) => {}
};

export const AuthContext = createContext<IAuthContext>(initialAuthContextValues);

const AuthProvider: FC<IProps> = ({ children }) => {
  const { data: userData, isLoading } = useUserInfo();
  const loginService = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const { removeLocalStorageItem } = useLocalStorage();

  const handleLogin = async (values: ILoginValues) => {
    try {
      const response = await loginService(values);
      if (response.success) {
        navigate(`${location.state?.from?.pathname}${location.state?.from?.search}` || RouteBasePath.CLIENT_BASE_URL);
      }
    } catch (error) {
      toast.error(generateToastErrorMsg(error as string | string[]));
    }
  };

  const handleLogout = (isReloadPage = true) => {
    removeLocalStorageItem(STORAGE_AUTH_ACCESS_TOKEN);

    isReloadPage && window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        onLogin: handleLogin,
        isLoading,
        onLogout: handleLogout
      }}
    >
      {isLoading && <LoadingMask />}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
