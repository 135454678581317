import './LoadingMask.scss';

import { FC } from 'react';

interface IProps {
  className?: string;
}

const LoadingMask: FC<IProps> = ({ className = '' }) => {
  return (
    <div className={`loading-mask__container ${className}`}>
      <div className='loading-mask__item'></div>
    </div>
  );
};

export default LoadingMask;
