import { headerMenu } from 'components/header/configs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface IProps {
  className?: string;
}

const HeaderMenuItem: FC<IProps> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <ul className={`flex flex-col p-4 mt-4 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 text-lg lg:text-xl font-medium ${className}`}>
      {headerMenu.map((menu, index) => (
        <li key={index}>
          <NavLink to={menu.path} className='block py-2 pr-4 pl-3 text-hl-black lg:p-0'>
            {t(`${menu.labelCode}`)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default HeaderMenuItem;
