import { RouteBasePath } from 'components/mainLayout/routeBasePath';
import { lazy } from 'react';

import { ADMIN_BASE_PATH, MY_ACCOUNT_PATH } from '@app/common/helpers';

const PageNotFound = lazy(() => import('pages/pageNotFound'));
const ClientRoot = lazy(() => import('pages/client'));
const ComingSoon = lazy(() => import('pages/client/comingSoon'));
const ProductsPageRoot = lazy(() => import('pages/client/products'));
const AllProducts = lazy(() => import('pages/client/products/allProducts'));
const ProductDetail = lazy(() => import('pages/client/products/productDetail'));
const CartPageRoot = lazy(() => import('pages/client/cart'));
const CartSummary = lazy(() => import('pages/client/cart/cartSummary'));
const Checkout = lazy(() => import('pages/client/cart/checkout'));
const AuthenticationPageRoot = lazy(() => import('pages/authentication'));
const Login = lazy(() => import('pages/authentication/login'));
const Register = lazy(() => import('pages/authentication/register'));
const AccountDetail = lazy(() => import('pages/client/myAccount'));
const AdminRoot = lazy(() => import('pages/admin'));
const Translation = lazy(() => import('pages/admin/translationManage'));
const MyProfile = lazy(() => import('pages/client/myAccount/myProfile'));
const MyAccountMenuListing = lazy(() => import('pages/client/myAccount/myAccountMenuListing'));
const MyAddress = lazy(() => import('pages/client/myAccount/myAddress'));
const ChangePassword = lazy(() => import('pages/client/myAccount/changePassword'));
const ManageAccount = lazy(() => import('pages/admin/manageAccount'));
const UserAccountDetail = lazy(() => import('pages/admin/userAccountDetail'));
const ManageCategory = lazy(() => import('pages/admin/categoryManage'));
const ManageProduct = lazy(() => import('pages/admin/productManage'));
const AddNewProductPage = lazy(() => import('pages/admin/productManage/addNewProductPage'));
const ProductDetailPage = lazy(() => import('pages/admin/productManage/viewProductDetailPage'));
const EditProductPage = lazy(() => import('pages/admin/productManage/editProductPage'));
const MyProductFavoritePage = lazy(() => import('pages/client/myFavorite'));

export interface IRoute {
  label: string;
  path: string;
  isPrivate: boolean;
  element: any;
  children: IChildRoute[];
}

export interface IChildRoute extends Omit<IRoute, 'isPrivate' | 'children'> {
  isIndex: boolean;
}

export const routes: IRoute[] = [
  {
    label: 'header.home',
    path: RouteBasePath.CLIENT_BASE_URL,
    isPrivate: false,
    element: <ClientRoot />,
    children: []
  },
  {
    label: 'header.product', // using i18n key for label
    path: RouteBasePath.PRODUCT_PAGE_PATH,
    isPrivate: false,
    element: <ProductsPageRoot />,
    children: [
      {
        label: '',
        path: '',
        element: <AllProducts />,
        isIndex: true
      }
    ]
  },
  {
    label: 'labels.product_detail',
    path: RouteBasePath.PRODUCT_DETAIL_PAGE_PATH,
    element: <ProductDetail />,
    isPrivate: false,
    children: []
  },
  {
    label: 'labels.cart_detail',
    path: RouteBasePath.CART_PATH,
    element: <CartPageRoot />,
    isPrivate: false,
    children: [
      {
        label: '',
        path: '',
        element: <CartSummary />,
        isIndex: true
      },
      {
        label: 'labels.cart_checkout',
        path: RouteBasePath.CHECKOUT_PAGE_PATH,
        element: <Checkout />,
        isIndex: false
      }
    ]
  },
  {
    label: 'labels.authentication',
    path: RouteBasePath.AUTH_PAGE_PATH,
    element: <AuthenticationPageRoot />,
    isPrivate: false,
    children: [
      {
        label: 'labels.login',
        path: RouteBasePath.LOGIN_PAGE_PATH,
        element: <Login />,
        isIndex: true
      },
      {
        label: 'labels.register',
        path: RouteBasePath.REGISTER_PAGE_PATH,
        element: <Register />,
        isIndex: false
      }
    ]
  },
  {
    label: 'labels.my_account',
    path: MY_ACCOUNT_PATH,
    element: <AccountDetail />,
    isPrivate: true,
    children: [
      {
        label: '',
        path: '',
        element: <MyAccountMenuListing />,
        isIndex: true
      },
      {
        label: 'labels.my_profile',
        path: RouteBasePath.MY_PROFILE_PATH,
        element: <MyProfile />,
        isIndex: false
      },
      {
        label: 'labels.my_address',
        path: RouteBasePath.MY_ADDRESS_PATH,
        element: <MyAddress />,
        isIndex: false
      },
      {
        label: 'labels.change_password',
        path: RouteBasePath.CHANGE_PASSWORD_PATH,
        element: <ChangePassword />,
        isIndex: false
      }
    ]
  },
  {
    label: 'labels.my_favorite',
    path: RouteBasePath.WISHLIST_PAGE_PATH,
    element: <MyProductFavoritePage />,
    isPrivate: true,
    children: []
  },
  {
    label: 'admin.label.admin',
    path: ADMIN_BASE_PATH,
    isPrivate: true,
    element: <AdminRoot />,
    children: [
      {
        label: 'admin.label.translation',
        path: RouteBasePath.TRANSLATION_PAGE_PATH,
        element: <Translation />,
        isIndex: true
      },
      {
        label: 'admin.label.account_manage',
        path: RouteBasePath.MANAGE_ACCOUNT_PAGE_PATH,
        element: <ManageAccount />,
        isIndex: false
      },
      {
        label: 'admin.label.account_detail',
        path: `${RouteBasePath.MANAGE_ACCOUNT_PAGE_PATH}/:accountId`,
        element: <UserAccountDetail />,
        isIndex: false
      },
      {
        label: 'admin.label.category_manage',
        path: RouteBasePath.CATEGORY_MANAGE_PAGE_PATH,
        element: <ManageCategory />,
        isIndex: false
      },
      {
        label: 'admin.label.product_manage',
        path: RouteBasePath.PRODUCT_MANAGE_PAGE_PATH,
        element: <ManageProduct />,
        isIndex: false
      },
      {
        label: 'admin.label.add_new_product',
        path: `${RouteBasePath.PRODUCT_MANAGE_PAGE_PATH}/${RouteBasePath.ADD_PRODUCT_PAGE_PATH}`,
        element: <AddNewProductPage />,
        isIndex: false
      },
      {
        label: 'admin.label.product_detail',
        path: `${RouteBasePath.PRODUCT_MANAGE_PAGE_PATH}/:productId`,
        element: <ProductDetailPage />,
        isIndex: false
      },
      {
        label: 'admin.label.edit_product',
        path: `${RouteBasePath.PRODUCT_MANAGE_PAGE_PATH}/${RouteBasePath.EDIT_PRODUCT_PAGE_PATH}/:productId`,
        element: <EditProductPage />,
        isIndex: false
      }
    ]
  },
  {
    label: '',
    path: RouteBasePath.COMING_SOON_PAGE_PATH,
    isPrivate: false,
    element: <ComingSoon />,
    children: []
  },
  {
    label: '',
    path: '*',
    isPrivate: false,
    element: <PageNotFound />,
    children: []
  }
];
