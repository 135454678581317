import { STORAGE_AUTH_ACCESS_TOKEN } from 'helpers/constant';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useCallback } from 'react';
import { AUTH_BASE_URL } from 'services/auth/config';
import { useMutateUserInfo } from 'services/auth/useUserInfo';
import axiosInstance from 'services/utils/axiosInstance';

interface ILoginData {
  emailOrUsername: string;
  password: string;
}

const useLogin = () => {
  const mutateCase = useMutateUserInfo();
  const { saveLocalStorageItem } = useLocalStorage();

  return useCallback(
    async (data: ILoginData) => {
      const { emailOrUsername, password } = data;

      const response = await axiosInstance.post(`${AUTH_BASE_URL}/login`, {
        emailOrUsername: emailOrUsername,
        password: password
      });

      saveLocalStorageItem(STORAGE_AUTH_ACCESS_TOKEN, response.data.accessToken);

      mutateCase();

      return response;
    },
    [mutateCase]
  );
};

export default useLogin;
