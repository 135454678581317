import { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currentLngSelector } from 'store/features/language';
import { changeLanguage } from 'store/features/language/languageSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { TLocaleNamespace } from '@app/common/types';

interface IProps extends ComponentPropsWithoutRef<'div'> {
  className?: string;
}

const LanguagesSelect: FC<IProps> = ({ className = '', ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentLng } = useAppSelector(currentLngSelector);

  const handleChangeLanguage = (locale: TLocaleNamespace) => {
    dispatch(changeLanguage(locale));
  };

  return (
    <div className={`text-center font-hl-mulish text-sm flex items-center space-x-1.5 text-hl-grey ${className}`} {...props}>
      <p>{t('labels.languages')}:</p>
      <button onClick={() => handleChangeLanguage('vi-VN')} className={`${currentLng === 'vi-VN' ? 'underline' : ''}`}>
        VI
      </button>
      <span>|</span>
      <button onClick={() => handleChangeLanguage('en-US')} className={`${currentLng === 'en-US' ? 'underline' : ''}`}>
        EN
      </button>
    </div>
  );
};

export default LanguagesSelect;
