import './Header.scss';

import LogoMobile from 'assets/images/logo_mobile.svg';
import LogoDesktop from 'assets/images/logo-svg.svg';
import HeaderMenuFeatures from 'components/header/headerMenuFeatures';
import HeaderMenuItem from 'components/header/HeaderMenuItem';
import { LG_SCREEN_BREAKPOINT } from 'helpers/constant';
import { useClickOutside } from 'hooks/useClickOutside';
import { useWindowSize } from 'hooks/useWindowSize';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Header: FC = () => {
  const headerContainerRef = useRef<HTMLElement>(null);
  const windowSize = useWindowSize();
  const [isShowHeaderMenu, setIsShowHeaderMenu] = useState<boolean>(false);
  const [headerMenuTop, setHeaderMenuTop] = useState<number>(0);
  const [isStickyHeader, setIsStickyHeader] = useState<boolean>(false);

  useEffect(() => {
    const handleSetHeaderMenuTopSpace = () => setHeaderMenuTop(headerContainerRef?.current?.offsetHeight || 0);

    window.addEventListener('resize', handleSetHeaderMenuTopSpace);

    handleSetHeaderMenuTopSpace();

    return () => window.removeEventListener('resize', handleSetHeaderMenuTopSpace);
  }, [headerContainerRef]);

  useEffect(() => {
    const handleSetHeaderType = () => {
      const windowScrollTop = window.scrollY || 0;

      if (windowScrollTop >= headerMenuTop) setIsStickyHeader(true);
      else if (windowScrollTop === 0) setIsStickyHeader(false);
    };

    window.addEventListener('scroll', handleSetHeaderType);

    handleSetHeaderType();

    return () => window.removeEventListener('scroll', handleSetHeaderType);
  }, [headerMenuTop]);

  const handleOpenCloseHeaderMenu = () => setIsShowHeaderMenu(!isShowHeaderMenu);
  const handleCloseHeaderMenu = () => setIsShowHeaderMenu(false);

  const openHeaderMenuBtnRef = useClickOutside(handleCloseHeaderMenu);

  return (
    <nav
      id='header-container'
      ref={headerContainerRef}
      className={`${
        isStickyHeader ? 'bg-white header-container__sticky' : 'static bg-hl-beige'
      } left-0 px-2 sm:px-4 py-2.5 w-full z-50 border-b border-hl-black-20 nav-link__container header-container`}
    >
      <div className='container flex flex-wrap justify-between items-center mx-auto relative'>
        <Link to={'/'} className='flex items-center justify-center'>
          <div className='w-9 sm:w-14 mr-3'>
            <div className='pb-100% relative w-full'>
              <img src={LogoDesktop} className='absolute hidden md:block w-full h-full left-0 top-0 object-cover' alt='KimLocFloral-Logo' />
              <img src={LogoMobile} className='absolute md:hidden w-full h-full left-0 top-0 object-cover' alt='KimLocFloral-Logo' />
            </div>
          </div>
        </Link>
        <div className='flex lg:order-2'>
          <div className='mr-3 lg:mr-0 text-hl-black flex justify-center items-center space-x-4 sm:space-x-9'>
            <HeaderMenuFeatures />
          </div>
          <button
            ref={openHeaderMenuBtnRef}
            className='flex flex-col items-center justify-center space-y-1 lg:hidden hover:text-hl-primary focus:outline-none overflow-hidden relative header-button'
            data-header-button={isShowHeaderMenu}
            onClick={handleOpenCloseHeaderMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div
          className={`${
            isShowHeaderMenu ? 'header-sticky__menu-active' : ''
          } justify-between items-center w-full lg:flex lg:w-auto lg:order-1 z-40 bg-hl-beige lg:bg-transparent header-sticky__menu`}
          style={{
            top: `${headerMenuTop}px`,
            left: windowSize.width < LG_SCREEN_BREAKPOINT ? `-${windowSize.width}px` : ''
          }}
        >
          <HeaderMenuItem />
        </div>
      </div>
    </nav>
  );
};

export default Header;
