import { useCallback } from 'react';
import { PRODUCT_FAVORITE_BASE_URL } from 'services/productFavorite/config';
import { useMutateProductFavorite } from 'services/productFavorite/useGetProductFavorite';
import { axiosInstance } from 'services/utils';

const useAddRemoveProductFavorite = () => {
  const mutateCase = useMutateProductFavorite();

  return useCallback(
    async (productId: string) => {
      const response = await axiosInstance.post(`${PRODUCT_FAVORITE_BASE_URL}/add-remove-product-favorite`, {
        productId: productId
      });

      mutateCase();

      return response;
    },
    [mutateCase]
  );
};

export default useAddRemoveProductFavorite;
