export const useLocalStorage = () => {
  const saveLocalStorageItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

  const getLocalStorageItem = (key: string) => {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch {
        return data;
      }
    }

    return {};
  };

  const removeLocalStorageItem = (key: string) => localStorage.removeItem(key);

  return {
    saveLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem
  };
};
