import { useCallback } from 'react';
import { PRODUCT_FAVORITE_BASE_URL } from 'services/productFavorite/config';
import { generateProductFavoriteObject } from 'services/productFavorite/utils';
import { fetcher } from 'services/utils';
import useSWR, { useSWRConfig } from 'swr';

const getProductFavoriteBaseUrl = `${PRODUCT_FAVORITE_BASE_URL}/get-product-favorite`;

export const useMutateProductFavorite = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(getProductFavoriteBaseUrl);
  }, [mutate]);
};

const useGetProductFavorite = () => {
  const { data, isValidating, error, mutate } = useSWR(getProductFavoriteBaseUrl, fetcher);

  return {
    data: data ? generateProductFavoriteObject(data.data) : null,
    isLoading: isValidating,
    mutate,
    errorMessage: error
  };
};

export default useGetProductFavorite;
