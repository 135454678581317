import axios from 'axios';
import { STORAGE_AUTH_ACCESS_TOKEN } from 'helpers/constant';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { getResponseErrorMessage } from 'utils/getResponseErrorMessage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { getLocalStorageItem } = useLocalStorage();
    const access = getLocalStorageItem(STORAGE_AUTH_ACCESS_TOKEN);

    if (access && typeof access === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.Authorization = `Bearer ${access}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(getResponseErrorMessage(error));
  }
);

export default axiosInstance;
