export enum RouteBasePath {
  CLIENT_BASE_URL = '/',
  PRODUCT_PAGE_PATH = 'products',
  PRODUCT_DETAIL_PAGE_PATH = 'products/product-detail/:productId',
  CART_PATH = 'cart',
  MY_PROFILE_PATH = 'my-profile',
  MY_ADDRESS_PATH = 'my-address',
  CHECKOUT_PAGE_PATH = 'checkout',
  COMING_SOON_PAGE_PATH = 'coming-soon',
  AUTH_PAGE_PATH = 'auth',
  LOGIN_PAGE_PATH = 'login',
  REGISTER_PAGE_PATH = 'register',
  CHANGE_PASSWORD_PATH = 'change-password',
  TRANSLATION_PAGE_PATH = 'translation',
  MANAGE_ACCOUNT_PAGE_PATH = 'manage-account',
  PRODUCT_MANAGE_PAGE_PATH = 'manage-product',
  ADD_PRODUCT_PAGE_PATH = 'add-new-product',
  CATEGORY_MANAGE_PAGE_PATH = 'manage-category',
  PAGE_NOT_FOUND_PATH = '/page-not-found',
  EDIT_PRODUCT_PAGE_PATH = 'edit-product',
  SEARCH_PAGE_PATH = '/search',
  ABOUT_US_PAGE_PATH = '/about-us',
  WISHLIST_PAGE_PATH = '/wishlist'
}
