export const DEFAULT_LOCALE = 'vi-VN';

export const ADMIN_ROLE = 0;

export const USER_ROLE = 1;

export const ACCOUNT_IS_VERIFIED = true;

export const ACCOUNT_IS_NOT_VERIFIED = false;

export const ACTIVE_STATUS = true;

export const DISABLED_STATUS = false;

export const AUTH_LOGIN_PATH = '/auth/login';

export const AUTH_REGISTER_PATH = '/auth/register';

export const ADMIN_BASE_PATH = '/admin';

export const MY_ACCOUNT_PATH = '/my-account';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const EMAIL_VERIFICATION_QUERY_PARAMS = 'emailVerificationToken';

export const MAX_ALLOW_UPLOAD_PRODUCT_IMAGE = 5;
