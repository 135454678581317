import _ from 'lodash';

import { IProductFavoriteData, IProductFavoriteItem } from '@app/common/types';

export const generateProductFavoriteItemObject = (data: any): IProductFavoriteItem => ({
  id: _.get(data, 'id', ''),
  productNameVi: _.get(data, 'productNameVi', ''),
  productImages: _.get(data, 'productImages', ''),
  productNameEn: _.get(data, 'productNameEn', ''),
  linkToBuy: _.get(data, 'linkToBuy', ''),
  price: _.get(data, 'price', '')
});

export const generateProductFavoriteObject = (data: any): IProductFavoriteData => ({
  id: _.get(data, 'id', ''),
  userId: _.get(data, 'userId', ''),
  products: data?.products.length ? data.products.map((product: any) => generateProductFavoriteItemObject(product)) : []
});
