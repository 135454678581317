import { headerFeatures } from 'components/header/configs';
import MyAccountIcon from 'components/header/headerMenuFeatures/MyAccountIcon';
import MyFavoriteIcon from 'components/header/headerMenuFeatures/MyFavoriteIcon';
import { RouteBasePath } from 'components/mainLayout/routeBasePath';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { MY_ACCOUNT_PATH } from '@app/common/helpers';

const HeaderMenuFeatures: FC = () => {
  return (
    <>
      {headerFeatures.map((feature) =>
        feature.path !== MY_ACCOUNT_PATH ? (
          feature.path !== RouteBasePath.WISHLIST_PAGE_PATH ? (
            <NavLink to={feature.path} key={feature.path} className='w-7 h-7 sm:w-8 sm:h-8 hover:text-hl-primary flex justify-center items-center'>
              {feature.icon}
            </NavLink>
          ) : (
            <MyFavoriteIcon key={feature.path} path={feature.path} icon={feature.icon} />
          )
        ) : (
          <MyAccountIcon key={feature.path} path={feature.path} icon={feature.icon} />
        )
      )}
    </>
  );
};

export default HeaderMenuFeatures;
