export const getResponseErrorMessage = (error: any) => {
  if (error?.response?.data?.message) return error.response.data.message;
  if (error?.response?.data?.error) {
    if (typeof error?.response?.data?.error.message !== 'string') {
      return error?.response?.data?.error.message.map((message: any) => message.msg);
    } else {
      return error?.response?.data?.error.message;
    }
  }

  return error?.message;
};
