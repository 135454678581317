import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { TRANSLATION_BASE_URL } from 'services/translationManage/config';
import axiosInstance from 'services/utils/axiosInstance';

import { DEFAULT_LOCALE } from '@app/common/helpers';

export const I18NEXT_LOCAL_STORAGE_NAME = 'i18nextLng';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options: any, url: string, payload: any, callback: any) => {
    const [lng] = url.split('|');
    try {
      axiosInstance.get(`${TRANSLATION_BASE_URL}/get-all?lng=${lng}`).then(({ data }) => {
        callback(null, {
          data,
          status: 200
        });
      });
    } catch (e) {
      callback(null, {
        status: 500
      });
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    backend: backendOptions,
    fallbackLng: DEFAULT_LOCALE,
    debug: false,
    saveMissing: true,
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
      order: ['queryString', 'localStorage'],
      caches: ['localStorage']
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
