import Spinner from 'components/spinner';
import { useAuthContext } from 'hooks/useAuthContext';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface IProps {
  path: string;
  icon: ReactElement;
}

const MyAccountIcon: React.FC<IProps> = ({ path, icon }) => {
  const { t } = useTranslation();
  const { userData, isLoading } = useAuthContext();

  return (
    <NavLink
      title={userData?.userInfo?.fullName}
      to={path}
      className='flex justify-center items-center hover:text-hl-primary flex justify-center items-center'
    >
      <span className='w-7 h-7 sm:w-8 sm:h-8'>{icon}</span>
      {!isLoading ? (
        userData?.userInfo?.fullName ? (
          <span className='w-20 font-hl-mulish truncate'>
            {t('labels.hi')} {userData?.userInfo?.fullName}
          </span>
        ) : (
          <></>
        )
      ) : (
        <Spinner className='w-3.5 h-3.5' />
      )}
    </NavLink>
  );
};

export default MyAccountIcon;
