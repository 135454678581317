import { AUTH_BASE_URL } from 'services/auth/config';
import axiosInstance from 'services/utils/axiosInstance';
import { IRegisterValues } from 'types/auth';
import { getCurrentLng } from 'utils/getCurrentLng';

export const registerService = async (registerData: IRegisterValues) => {
  const { fullName, username, email, password, confirmPassword } = registerData;

  return await axiosInstance.post(`${AUTH_BASE_URL}/create?lng=${getCurrentLng()}`, {
    fullName: fullName,
    username: username,
    email: email,
    password: password,
    passwordConfirmation: confirmPassword
  });
};
