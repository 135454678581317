import Footer from 'components/footer';
import Header from 'components/header';
import { RouteBasePath } from 'components/mainLayout/routeBasePath';
import { routes } from 'components/mainLayout/routes';
import ScrollTop from 'components/scrollTop';
import { useAuthContext } from 'hooks/useAuthContext';
import { FC, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ADMIN_BASE_PATH, ADMIN_ROLE, AUTH_LOGIN_PATH } from '@app/common/helpers';

const MainLayout: FC = () => {
  const location = useLocation();
  const { userData, isLoading } = useAuthContext();
  const genHeaderComp = useMemo(() => <Header />, []);
  const genFooterComp = useMemo(() => <Footer />, []);
  const genScrollTop = useMemo(() => <ScrollTop />, []);

  const handleGenerateRoute = (path: string, isPrivate: boolean, element: any) => {
    if (isPrivate) {
      if (userData) {
        if (path === ADMIN_BASE_PATH && userData.role !== ADMIN_ROLE) {
          return <Navigate to={RouteBasePath.PAGE_NOT_FOUND_PATH} />;
        }

        return element;
      } else return <Navigate to={AUTH_LOGIN_PATH} state={{ from: location }} />;
    }
    if (path === 'auth' && userData) {
      return <Navigate to={`${location.state?.from?.pathname}${location.state?.from?.search}` || RouteBasePath.CLIENT_BASE_URL} />;
    }

    return element;
  };

  return (
    <div className='relative'>
      {!location.pathname.includes(ADMIN_BASE_PATH) ? genHeaderComp : <></>}
      <Routes>
        {routes.map((route) => (
          <Route key={route.label} path={route.path} element={!isLoading && handleGenerateRoute(route.path, route.isPrivate, route.element)}>
            {route.children &&
              route.children.length > 0 &&
              route.children?.map((child) => <Route key={child.label} path={child.path} element={child.element} index={child?.isIndex} />)}
          </Route>
        ))}
      </Routes>
      {!location.pathname.includes(ADMIN_BASE_PATH) ? genFooterComp : <></>}
      {genScrollTop}
    </div>
  );
};

export default MainLayout;
