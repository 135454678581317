import { ComponentPropsWithoutRef, FC } from 'react';

interface IProps extends ComponentPropsWithoutRef<'div'> {
  className?: string;
  children: JSX.Element | JSX.Element[];
}

const Container: FC<IProps> = ({ className = '', children, ...props }) => {
  return (
    <div {...props} className={`container mx-auto px-2 sm:px-4 ${className}`}>
      {children}
    </div>
  );
};

export default Container;
