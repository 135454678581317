import Spinner from 'components/spinner';
import React, { ReactElement, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetProductFavorite } from 'services/productFavorite';

interface IProps {
  path: string;
  icon: ReactElement;
}

const MyFavoriteIcon: React.FC<IProps> = ({ path, icon }) => {
  const { data, isLoading } = useGetProductFavorite();

  const productFavoriteLength = useMemo(() => {
    return data?.products.length || 0;
  }, [data]);

  return (
    <NavLink to={path} className='flex justify-center items-center hover:text-hl-primary flex justify-center items-center'>
      <span className='w-7 h-7 sm:w-8 sm:h-8'>
        {React.cloneElement(icon, { className: productFavoriteLength ? 'text-hl-primary fill-hl-primary' : '' })}
      </span>
      {!isLoading ? <span className='font-hl-mulish'>({productFavoriteLength})</span> : <Spinner className='w-3.5 h-3.5' />}
    </NavLink>
  );
};

export default MyFavoriteIcon;
