import { useCallback } from 'react';
import { AUTH_BASE_URL } from 'services/auth/config';
import { useMutateUserInfo } from 'services/auth/useUserInfo';
import axiosInstance from 'services/utils/axiosInstance';

import { IUserInfo } from '@app/common/types';

export interface IUserProfileUpdateFields extends Omit<IUserInfo, 'fullName'> {
  fullName?: string;
}

const useUpdateProfile = () => {
  const mutateCase = useMutateUserInfo();

  return useCallback(
    async (profileData: { userId: string } & IUserProfileUpdateFields) => {
      const { userId, fullName, gender, dob, phoneNumber, city, district, ward, addressDetail } = profileData;

      const response = await axiosInstance.patch(`${AUTH_BASE_URL}/update/${userId}`, {
        fullName: fullName,
        gender: gender,
        dob: dob,
        phoneNumber: phoneNumber,
        city: city,
        district: district,
        ward: ward,
        addressDetail: addressDetail
      });

      mutateCase();

      return response;
    },
    [mutateCase]
  );
};

export default useUpdateProfile;
