import { AUTH_BASE_URL } from 'services/auth/config';
import axiosInstance from 'services/utils/axiosInstance';
import { getCurrentLng } from 'utils/getCurrentLng';

export interface IChangePasswordFields {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

export const changePasswordService = async (data: IChangePasswordFields) => {
  const { oldPassword, newPassword, passwordConfirmation } = data;

  return await axiosInstance.patch(`${AUTH_BASE_URL}/change-password?lng=${getCurrentLng()}`, {
    oldPassword: oldPassword,
    newPassword: newPassword,
    passwordConfirmation: passwordConfirmation
  });
};
