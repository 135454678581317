import Container from 'components/container';
import LanguagesSelect from 'components/languagesSelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='bg-hl-beige mt-8'>
      <Container>
        {/* <div className='flex flex-col xl:flex-row justify-between items-center py-8 xl:py-16 space-y-4 lg:space-y-0'> */}
        {/*   <div className='max-w-120px w-full'> */}
        {/*     <div className='w-full pb-100% relative'> */}
        {/*       <img src={Logo} alt={genImageAltText('logo')} className='absolute w-full h-full left-0 top-0 object-cover' /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/*   <div className='max-w-490px w-full space-y-3 lg:space-y-6'> */}
        {/*     <p className='text-lg xl:text-xl font-medium text-center capitalize xl:text-left'>{t('labels.subscribe_newsletter')}</p> */}
        {/*     <div className='space-y-4'> */}
        {/*       <SubscribeNewsLetterInput /> */}
        {/*       <div className='w-full flex justify-center xl:justify-start'> */}
        {/*         <Social className='justify-center xl:justify-start' /> */}
        {/*       </div> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
        <div className='w-full font-hl-mulish text-hl-grey text-base font-normal flex flex-col lg:flex-row  space-y-2 lg:space-y-0 justify-between items-center p-2'>
          <p>{t('labels.footer_copy_rights')}</p>
          <LanguagesSelect style={{ fontSize: '16px', color: '#311C1C' }} />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
