import { RouteBasePath } from 'components/mainLayout/routeBasePath';
import { ReactElement } from 'react';

import { MY_ACCOUNT_PATH } from '@app/common/helpers';

interface IHeaderMenu {
  labelCode: string;
  path: string;
}

interface IHeaderFeature {
  path: string;
  icon: ReactElement;
}

export const headerMenu: IHeaderMenu[] = [
  {
    labelCode: 'header.home',
    path: RouteBasePath.CLIENT_BASE_URL
  },
  {
    labelCode: 'header.product',
    path: `/${RouteBasePath.PRODUCT_PAGE_PATH}`
  },
  {
    labelCode: 'header.about',
    path: RouteBasePath.ABOUT_US_PAGE_PATH
  }
];

export const headerFeatures: IHeaderFeature[] = [
  {
    path: RouteBasePath.SEARCH_PAGE_PATH,
    icon: (
      <svg className='w-full h-full' width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.668 20.666L25.3346 25.3327L20.668 20.666ZM6.66797 14.666C6.66797 15.7166 6.87489 16.7569 7.27693 17.7275C7.67897 18.6981 8.26825 19.58 9.01111 20.3229C9.75398 21.0657 10.6359 21.655 11.6065 22.0571C12.5771 22.4591 13.6174 22.666 14.668 22.666C15.7185 22.666 16.7588 22.4591 17.7294 22.0571C18.7 21.655 19.582 21.0657 20.3248 20.3229C21.0677 19.58 21.657 18.6981 22.059 17.7275C22.461 16.7569 22.668 15.7166 22.668 14.666C22.668 12.5443 21.8251 10.5095 20.3248 9.00916C18.8245 7.50887 16.7897 6.66602 14.668 6.66602C12.5462 6.66602 10.5114 7.50887 9.01111 9.00916C7.51082 10.5095 6.66797 12.5443 6.66797 14.666V14.666Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  },
  {
    path: RouteBasePath.WISHLIST_PAGE_PATH,
    icon: (
      <svg className='w-full h-full' width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M26 13.1552C26.0036 14.7709 25.3811 16.3252 24.2633 17.4917C21.7002 20.1493 19.2138 22.9203 16.5562 25.4802C15.9461 26.0577 14.9791 26.0367 14.3963 25.433L6.73646 17.4928C4.42118 15.0924 4.42118 11.2179 6.73646 8.81862C7.28366 8.24426 7.94181 7.78701 8.67099 7.47458C9.40017 7.16215 10.1852 7.00105 10.9785 7.00105C11.7718 7.00105 12.5568 7.16215 13.286 7.47458C14.0152 7.78701 14.6734 8.24426 15.2206 8.81862L15.4999 9.10632L15.7781 8.81862C16.3264 8.24562 16.9848 7.7892 17.7137 7.47671C18.4426 7.16421 19.2271 7.00207 20.0202 7C21.6162 7 23.1419 7.65521 24.2622 8.81862C25.3805 9.98502 26.0033 11.5393 26 13.1552V13.1552Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinejoin='round'
        />
      </svg>
    )
  },
  {
    path: MY_ACCOUNT_PATH,
    icon: (
      <svg className='w-full h-full' width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.66797 26.6673V25.334C6.66797 22.8586 7.6513 20.4847 9.40164 18.7343C11.152 16.984 13.5259 16.0007 16.0013 16.0007V16.0007C18.4767 16.0007 20.8506 16.984 22.601 18.7343C24.3513 20.4847 25.3346 22.8586 25.3346 25.334V26.6673H6.66797ZM16.0013 16.0007C17.4158 16.0007 18.7723 15.4387 19.7725 14.4386C20.7727 13.4384 21.3346 12.0818 21.3346 10.6673C21.3346 9.25283 20.7727 7.89628 19.7725 6.89608C18.7723 5.89589 17.4158 5.33398 16.0013 5.33398C14.5868 5.33398 13.2303 5.89589 12.2301 6.89608C11.2299 7.89628 10.668 9.25283 10.668 10.6673C10.668 12.0818 11.2299 13.4384 12.2301 14.4386C13.2303 15.4387 14.5868 16.0007 16.0013 16.0007V16.0007Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )
  }
];
