import _ from 'lodash';
import { useCallback } from 'react';
import { AUTH_BASE_URL } from 'services/auth/config';
import { fetcher } from 'services/utils';
import useSWR, { useSWRConfig } from 'swr';

import { IUserResponse } from '@app/common/types';

export const useMutateUserInfo = () => {
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(`${AUTH_BASE_URL}/get-auth`);
  }, [mutate]);
};

export interface IUseAuthData extends Omit<IUserResponse, '_id'> {
  _id: string;
}

const useUserInfo = () => {
  const { data, error, mutate, isValidating } = useSWR(`${AUTH_BASE_URL}/get-auth`, fetcher);

  const userInfoData: IUseAuthData = {
    _id: _.get(data?.data, '_id'),
    username: _.get(data?.data, 'username'),
    email: _.get(data?.data, 'email'),
    role: _.get(data?.data, 'role'),
    isVerified: _.get(data?.data, 'isVerified'),
    status: _.get(data?.data, 'status'),
    createdAt: _.get(data?.data, 'createdAt'),
    createdBy: _.get(data?.data, 'createdBy'),
    updatedAt: _.get(data?.data, 'updatedAt'),
    updatedBy: _.get(data?.data, 'updatedBy'),
    userInfo: _.get(data?.data, 'userInfo')
  };

  return {
    mutate,
    isLoading: isValidating,
    data: data ? userInfoData : null,
    errorMessage: error
  };
};

export default useUserInfo;
