export const generateToastErrorMsg = (errors: string | string[]) => {
  return typeof errors !== 'string' && errors.length ? (
    <>
      <ul>
        {errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </>
  ) : (
    errors
  );
};
