import { useParams } from 'react-router-dom';
import { PRODUCT_FAVORITE_BASE_URL } from 'services/productFavorite/config';
import { generateProductFavoriteObject } from 'services/productFavorite/utils';
import { fetcher } from 'services/utils';
import useSWR from 'swr';

const useGetProductFavoriteByUser = () => {
  const { accountId } = useParams();
  const { data, isValidating, error, mutate } = useSWR(`${PRODUCT_FAVORITE_BASE_URL}/get-product-favorite-by-user/${accountId}`, fetcher);

  return {
    data: data ? generateProductFavoriteObject(data.data) : null,
    isLoading: isValidating,
    mutate,
    errorMessage: error
  };
};

export default useGetProductFavoriteByUser;
