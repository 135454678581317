import './ScrollTop.scss';

import { FC, useEffect, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';

const ScrollTop: FC = () => {
  const [isActiveScrollTopBtn, setIsActiveScrollBtn] = useState<boolean>(false);

  useEffect(() => {
    const setActiveScrollTopBtn = () => (window.scrollY > 0 ? setIsActiveScrollBtn(true) : setIsActiveScrollBtn(false));

    window.addEventListener('scroll', setActiveScrollTopBtn);

    setActiveScrollTopBtn();

    return () => window.removeEventListener('scroll', setActiveScrollTopBtn);
  }, []);

  const handleScrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <div
      className='w-8 h-8 rounded-full border border-hl-black flex justify-center items-center fixed -bottom-full right-2 hover:bg-hl-primary-20 cursor-pointer z-50 scroll-top bg-white'
      data-active-scroll-btn={isActiveScrollTopBtn}
      onClick={handleScrollToTop}
    >
      <BsChevronUp className='text-hl-black' />
    </div>
  );
};

export default ScrollTop;
